.gallery-background { position:fixed; z-index:2; left:0; top:0; width:100%; height:100%; background:#000; box-sizing:border-box; user-select:none; }
.gallery-wrapper { position:absolute; user-select:none; left:50%; top:50%; transform:translate( -50%, -50% ); width:100%; height:100%; padding:0 0 20px 0; box-sizing:border-box; }
.gallery-image { position:absolute; backface-visibility:hidden; user-select:none; left:0; top:50%; transform:translateY(-50%); width:100%; height:100%; object-fit:contain; box-sizing:border-box; }
.gallery-image-left { position:absolute; backface-visibility:hidden; user-select:none; left:-100%; top:50%; transform:translateY(-50%); width:100%; height:100%; object-fit:contain; box-sizing:border-box; }
.gallery-image-right { position:absolute; backface-visibility:hidden; user-select:none; left:100%; top:50%; transform:translateY(-50%); width:100%; height:100%; object-fit:contain; box-sizing:border-box; }

.gallery-close { position:absolute; right:0; top:0; padding:30px 20px; cursor:pointer; user-select:none; color:white; font-size:28px; }
.gallery-close > i { padding:12px; color: #fffa; user-select:none; background-color:rgba(0,0,0,0.3); border-radius:50%; }
.gallery-close > i:hover { color: #ffff; }

.gallery-arrow-left { position:absolute; left:0; bottom:0; padding:30px 20px; cursor:pointer; user-select:none; color:white; font-size:28px; }
.gallery-arrow-left > i { padding:12px; color: #fffa; user-select:none; background-color:rgba(0,0,0,0.3); border-radius:50%; }
.gallery-arrow-left > i:hover { color: #ffff; }

.gallery-arrow-right { position:absolute; right:0; bottom:0; padding:30px 20px; cursor:pointer; user-select:none; color:white; font-size:28px; }
.gallery-arrow-right > i { padding:12px; color: #fffa; user-select:none; background-color:rgba(0,0,0,0.3); border-radius:50%; }
.gallery-arrow-right > i:hover { color: #ffff; }
