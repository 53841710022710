html, body { -webkit-tap-highlight-color:rgba(0,0,0,0); -webkit-tap-highlight-color: transparent; background:#fdfdfd; padding:0; margin:0; font-family: 'Lato', sans-serif; text-align:center; color:#445; font-size:18px; line-height:26px; width:100vw; max-width:100vw; overflow-x: hidden; }

body::-webkit-scrollbar { width: 10px; }
body::-webkit-scrollbar-track { background: #fff; }
body::-webkit-scrollbar-thumb { background-color: #bbb; border: 3px solid #fff; border-radius: 6px; }

label { font-family:Lato; font-size: 12px; font-weight: 900; user-select: none;  }
input { display: block; border: 1px solid #888; padding:8px 12px; font-family:Lato; font-size:14px; width:100%; max-width:280px; border-radius: 5px; }

h1 { font-weight:600; font-size:28px; margin-top:40px; }
h2 { font-weight:600; font-size:20px; }
h3 { font-weight:700; margin-top:25px; margin-bottom:10px; }
h4 { font-weight:600; }

strong { font-weight:500; padding:5px 0 5px 0; }
p { font-size:15px; margin-top:5px; margin-bottom:5px; }

a { text-decoration:none; color:black; }

.pointer { cursor:pointer; }
.shadow { -webkit-filter: drop-shadow( 0px 2.5px 5px rgba(0,0,0,0.6) ); filter: drop-shadow( 0px 2.5px 5px rgba(0,0,0,0.6) ); }

.green { background:rgb(102,187,106); background: linear-gradient(180deg, rgba(102,187,106,1) 0%, rgba(67,160,71,1) 20%, rgba(46,125,50,1) 90%, #1b5e20 100%); border: 1px solid rgba(46,125,50,1); }
.orange { background:#e65100; background: linear-gradient(180deg, #fb8c00 0%, #f57c00 20%, #ef6c00 90%, #e65100 100%); border: 1px solid #e65100; }
.gray { background:#888; background: linear-gradient(180deg, #ddd 0%, #aaa 100%); border: 1px solid #aaa; }

.mobile-only { display:block; }
.desktop-only { display:none; }
.flex { display:flex; flex-direction:column; justify-items: center; }

@media only screen and ( min-width: 768px ) and ( min-height: 412px ) {
	.mobile-only { display:none; }
	.desktop-only { display:block; }
	.flex { flex-direction: row; align-items: center; }
}

.info-wrapper { margin: 0; width:100%; padding:0 0 60px 0; box-sizing:border-box; background:#e7e7e7; text-align:left; }

.info { display:inline-block; width:100%; box-sizing:border-box; text-align:left; padding:0 20px 0 10px; vertical-align:top; }
.categories-list-block { display:inline-block; width:100%; height:290px; padding:0; margin:10px 30px 10px 10px; box-sizing:border-box; background-color:white; box-shadow:0 4px 8px rgba(0,0,0,s0.2); color:black; }
.blogs-list-block { display:inline-block; width:100%; padding:0; margin:10px 30px 10px 10px; box-sizing:border-box; background-color:white; box-shadow:0 4px 8px rgba(0,0,0,0.2); color:black; }

@media only screen and ( min-width: 657px) {
	.info { width:49%; }
	.categories-list-block { width:270px; height:290px; padding:0; margin:10px; box-sizing:border-box; background-color:white; box-shadow:0 4px 8px rgba(0,0,0,0.2); color:black; }
	.blogs-list-block { width:270px; padding:0; margin:10px; box-sizing:border-box; background-color:white; box-shadow:0 4px 8px rgba(0,0,0,0.2); color:black; }
}
