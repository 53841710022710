
.front-wrapper { width:100%; height:50vh; overflow:hidden; background:#000; user-select:none; }
.front-shadow { position:absolute; left:0; top:0; width:100%; height:50vh; overflow:hidden; background-image:linear-gradient( rgba(0,0,0,0.2), rgba(0,0,0,0.4) ) }

.front { position:absolute; width:100%; left:0; bottom:0; padding:0 10px 20px 0; text-align:left; font-weight:400; text-shadow:0 5px 10px rgba(0,0,0,0.4); }
.front-arrow-down { position:absolute; bottom:0; left:50%; padding:20px 40px 60px 40px; user-select:none; transform:translateX(-50%); font-size:36px; text-shadow:0 5px 10px rgba(0,0,0,0.4); }

.front-link-hover { font-size: 13px; display: inline-block; padding: 0; margin: 5px 8px; border-bottom:3px solid rgba(255,255,255,0); }
.front-link-hover:hover { border-bottom:3px solid rgba( 255, 255, 255, .5 ); }

.button-front { border-radius:5px; padding:10px 20px 12px 20px; font-weight:600; margin:10px; text-decoration:none; color:white; font-size:14px; cursor:pointer; }
.button-front:hover { box-shadow: 0 6px 12px rgba( 0, 0, 0, 0.15 ); }

.front-links { display:flex; flex-direction:column; float: right; font-size:12px; font-weight: 700; color:white; top:0; right:0; cursor:pointer; padding:15px 0 0 10px; }

@media only screen and ( min-width: 657px) {
	.front-links { flex-direction: row; }
}
