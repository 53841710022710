
.footer-wrapper { margin:0; width:100%; text-align:left; font-size:11px; padding:20px 0 10px 0; background-color:#263238; color:white; vertical-align:top; box-sizing:border-box; user-select:none;  }
.footer { margin:auto; width:100%; max-width:1240px; padding:20px; vertical-align:top; box-sizing:border-box;  }
.footer-flex-box { display:block; }
.footer-item-group { padding:0 20px 20px 10px; line-height: 28px; }

@media only screen and ( min-width: 768px ) and ( min-height: 412px ) {
	.footer-flex-box { display:flex; }
	.footer-item-group { width:270px; padding-right:20px; }
}
