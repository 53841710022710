
.all-activities-tabs-container { position: relative; width:100%; max-width:100%; overflow-x:scroll; }
.all-activities-tabs-container::-webkit-scrollbar { height: 16px; width: 16px; }
.all-activities-tabs-container::-webkit-scrollbar-track { background: #fff; border-radius: 4px; }
.all-activities-tabs-container::-webkit-scrollbar-thumb { background-color: #fff; border: 6px solid #fff; border-radius: 8px; }

.all-activities-tabs { color:#666; padding:0 0 4px 0; box-sizing:border-box; font-size:14px; user-select:none; font-weight:600; cursor:pointer; border-bottom:1px solid #ccc; }
.all-activities-tabs-spacer { color:#666; padding:8px 0 4px 0; box-sizing:border-box; font-size:13px; user-select:none; font-weight:700; cursor:pointer; border-bottom:1px solid #ccc; }
.all-activities-tabs:hover { color:#1d57c7cc; border-bottom:4px solid #1d57c788; }
.all-activities-tabs-selected { color:#1d57c7; border-bottom:4px solid #1d57c7; }

.all-activities-pills { display:inline-block; margin:0 5px 5px 0; padding:2px 12px; border-radius:16px; border:1px solid #bbb; font-size:14px; font-weight:800; color:#999; user-select:none; cursor:pointer; }
.all-activities-pills:hover { background-color:#bbdefb88; color:black; }
.all-activities-pills-selected, .all-activities-pills-selected:hover { background-color:#1e88e5; border:1px solid #1e88e5; color:white; }

.activities-list-block { display:inline-block; width:100%; padding:0; margin:10px 30px 10px 10px; box-sizing:border-box; background-color:white; box-shadow:0 4px 8px rgba(0,0,0,0.2); color:black; }

@media only screen and ( min-width: 657px) {
	.activities-list-block { width:270px; padding:0; margin:10px; box-sizing:border-box; background-color:white; box-shadow:0 4px 8px rgba(0,0,0,0.2); color:black; }
}

