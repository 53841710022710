.products-wrapper { margin: 0; width:100%; padding:0 0 60px 0; box-sizing:border-box; background:#e7e7e7; text-align:left; }
.products { margin: auto; width:100%; padding:0 0 20px 0; max-width:1240px; box-sizing:border-box; user-select:none; }

.products-flex-box { display:block; }
.products-checkout { position:relative; width:100%; height:1572px; padding:20px; border-radius: 5px; box-shadow:0 3px 6px rgba(0,0,0,0.2); background:white; margin:0; box-sizing:border-box; }
.products-checkout-component { position:relative; width:100%; padding:20px; box-shadow:0 3px 6px rgba(0,0,0,0.2); background:white; margin:0; box-sizing:border-box; }

@media only screen and ( min-width: 768px ) and ( min-height: 412px ) {
	.products-flex-box { display:flex; }
	.products-checkout { width:380px; min-width:380px; margin:-90px 30px 140px 20px; }
	.products-checkout-component { width:380px; min-width:380px; margin:-90px 30px 140px 20px; }
}
